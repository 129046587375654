<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">Honda DCKK</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="require('@/assets/images/logo/logo.png')"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to Honda DCKK! 👋
          </b-card-title>
          <!-- <b-card-text class="mb-2"> Please sign-in to your account and start the adventure </b-card-text> -->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="ชื่อผู้ใช้"
                label-for="login-Username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ชื่อผู้ใช้"
                  rules="required"
                >
                  <b-form-input
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="login-username"
                    placeholder="โปรดระบุ"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">รหัสผ่าน</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="รหัสผ่าน"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || loading"
              >
                <b-spinner
                  v-if="loading"
                  small
                />
                <span class="align-middle ml-50">Sign in</span>
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { VBTooltip } from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async login() {
      const validate = await this.$refs.loginForm.validate()
      if (!validate) {
        return
      }
      this.loading = true
      const resp = await this.$http
        .post(
          'api/login',
          {
            emailOrUsername: this.username,
            password: this.password,
          },
          { useAlert: { successEnable: false } },
        )
        .catch(() => null)

      if (resp?.code !== 200) {
        this.loading = false
        this.gDisplayToast('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง', '', 'danger')
        return
      }

      const { token, user_info } = resp.data

      let ability = []

      if (user_info?.role === 'admin') {
        ability = [{ action: 'manage', subject: 'all' }]
      } else {
        ability.push({
          action: 'read',
          subject: 'ACL',
        })
        ability = [
          { action: 'read', subject: 'Auth' },
          {
            action: 'read',
            subject: 'ACL',
          },
        ]
      }

      useJwt.setToken(token)

      const userData = {
        id: 1,
        fullName: 'Honda DCKK',
        username: 'Honda DCKK',
        avatar: '/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png',
        email: 'dckk@gmail.com',
        role: ability[0].subject === 'all' ? 'admin' : 'client',
        ability,
        extras: { eCommerceCartItemsCount: 5 },
      }

      localStorage.setItem('userData', JSON.stringify(userData))
      this.$ability.update(ability)
      this.loading = false
      this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
